<template>
  <el-dropdown-item>
    <a :href="href">
      <svgicon
        :src="require('@/assets/icons/th-icon-download.svg')"
        :style="{ height: '20px', width: '20px' }"
        class="mr-2 fill-current"
      />
      <span>{{ $t('common.importer.template.download') }}</span>
    </a>
  </el-dropdown-item>
</template>

<script>
export default {
  name: 'DownloadTemplate',
  props: {
    href: {
      type: String,
      required: true
    }
  }
}
</script>
