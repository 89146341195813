import { createI18n } from 'vue-i18n'
import messagesEN from '@/locales/en-US'
import messagesDE from '@/locales/de-DE'

// Currently Flatfile get the language only from the browser, and can't be set by us via the dashboard language and Flatfile config.
// Therefore we can't rely on the dynamic root vue-i18n to translate text and we are using a local vue-i18n instance only for Flatfile related text.
const fallbackLocale = 'de'
const locale = window?.navigator?.language?.split('-')[0] || fallbackLocale
const messages = {
  en: messagesEN,
  de: messagesDE
}

// Taken from https://stackoverflow.com/questions/4098415/use-regex-to-get-image-url-in-html-js
// Regex for image URL that have to end with dot and some valid image format
// and it must have at the beggining //
// May or not have http and https at the beggining
export const regexURLImage =
  '(http)?s?:?(//[^"\']*.(?:png|jpg|jpeg|gif|png|svg))'

const i18n = createI18n({
  locale,
  messages,
  missingWarn: false,
  fallbackWarn: false,
  fallbackLocale
})

export const i18nOverrides = (extra = {}) => {
  const tm = i18n.global.tm
  return {
    alreadyMatched: tm('pages.importer.flatfile.alreadyMatched'),
    autoMatchedField1: tm('pages.importer.flatfile.autoMatchedField1'),
    breadcrumbs: {
      match: tm('pages.importer.flatfile.breadcrumbs.match')
    },
    buttons: {
      continue: tm('pages.importer.flatfile.buttons.continue'),
      selectAnotherRow: tm('pages.importer.flatfile.buttons.selectAnotherRow'),
      noHeaderRow: tm('pages.importer.flatfile.buttons.noHeaderRow')
    },
    noMatchSelected: tm('pages.importer.flatfile.noMatchSelected'),
    clearAllChanges: tm('pages.importer.flatfile.clearAllChanges'),
    excel: {
      stepFourDefault: tm('pages.importer.flatfile.excel.stepFourDefault'),
      stepTwoDefault: tm('pages.importer.flatfile.excel.stepTwoDefault')
    },
    dropzone: {
      accepted: '',
      button: tm('pages.importer.flatfile.dropzone.button'),
      description: tm('pages.importer.flatfile.dropzone.description')
    },
    fileTypes: tm('pages.importer.flatfile.dropzone.description'),
    lookupMatchingFields: tm('pages.importer.flatfile.lookupMatchingFields'),
    processingPleaseWait: tm('pages.importer.flatfile.processingPleaseWait'),
    unableToMatch: tm('pages.importer.flatfile.unableToMatch'),
    headerSelection: {
      question1: tm('pages.importer.flatfile.headerSelection.question1'),
      question2: tm('pages.importer.flatfile.headerSelection.question2'),
      tooltip1Title: tm(
        'pages.importer.flatfile.headerSelection.tooltip1Title'
      ),
      tooltip1Content: tm(
        'pages.importer.flatfile.headerSelection.tooltip1Content'
      ),
      tooltip2Title: tm(
        'pages.importer.flatfile.headerSelection.tooltip2Title'
      ),
      tooltip2Content: tm(
        'pages.importer.flatfile.headerSelection.tooltip2Content'
      ),
      encoding: tm('pages.importer.flatfile.headerSelection.encoding'),
      encodingTooltipTitle: tm(
        'pages.importer.flatfile.headerSelection.encodingTooltipTitle'
      ),
      encodingTooltipContent: tm(
        'pages.importer.flatfile.headerSelection.encodingTooltipContent'
      )
    },
    noData2: tm('pages.importer.flatfile.noData2'),
    encodingDetected: tm('pages.importer.flatfile.encodingDetected'),
    chooseOne: tm('pages.importer.flatfile.chooseOne'),
    ...extra
  }
}

export const createOptions = (list = []) =>
  list.map(({ id, name, fa_account_number, percentage }) => {
    return {
      value: id,
      label: `${fa_account_number} - ${name}`,
      alternates: [fa_account_number, name, ...generateAlternates(percentage)]
    }
  })

/*
 * this function will generate possible alternates for better matching by FlatFile
 * for example: 19 as input will generate
 * ["19", "19,0", "19.0", "19,0%", "19.0%", "0.19", "0,19"]
 */
function generateAlternates(value) {
  if (typeof value === 'number') {
    const floatValue = value.toFixed(1)
    return [
      `${value}`,
      `${floatValue}`.replace('.', ','),
      `${floatValue}`,
      `${floatValue}%`.replace('.', ','),
      `${floatValue}%`,
      `${value / 100}`,
      `${value / 100}`.replace('.', ',')
    ]
  }
  return []
}

export const splitArrayIntoChunks = (arr, chunkSize) => {
  const chunks = [],
    n = arr.length
  let i = 0
  while (i < n) {
    chunks.push(arr.slice(i, (i += chunkSize)))
  }
  return chunks
}
